@import "variables";
@import "./versions/2.0.6/base/scss/meetingSubscriptionFlowVariant1";
@import "./versions/2.0.6/base/scss/meetingVariant1";
@import "./versions/2.0.6/base/scss/datepicker";
// Theme - only 1 entry possible!!
@import "./versions/2.0.6/#themes/neptunus";

// inject:defaultWidgets:scss
@import "./versions/2.0.6/defaultWidgets/addFunctionVariant1/scss/_addFunctionVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/changeAttributesVariant1/scss/_changeAttributesVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/changeFunctionVariant1/scss/_changeFunctionVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/changePasswordVariant1/scss/_changePasswordVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/changePersonalInformationVariant1/scss/_changePersonInformationVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/cookieVariant1/scss/_cookieVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/cookieSettingsVariant1/scss/_cookieSettingsVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/documentsVariant1/scss/_documentsVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/documentDetailVariant1/scss/_documentDetailVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/footerVariant2/scss/_footerVariant2.scss";
@import "./versions/2.0.6/defaultWidgets/footerVariant3/scss/_footerVariant3.scss";
@import "./versions/2.0.6/defaultWidgets/formVariant1/scss/_formVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/galleryVariant1/scss/_galleryVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/headerVariant2/scss/_headerVariant2.scss";
@import "./versions/2.0.6/defaultWidgets/loginVariant1/scss/_loginVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/meetingsVariant1/scss/_meetingsVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/memberSearchVariant1/scss/_memberSearchVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/myPersonalInformationVariant1/scss/_myPersonalInformationVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/pagesVariant1/scss/_pagesVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/pagesVariant2/scss/_pagesVariant2.scss";
@import "./versions/2.0.6/defaultWidgets/pagesVariant3/scss/_pagesVariant3.scss";
@import "./versions/2.0.6/defaultWidgets/pagesVariant5/scss/_pagesVariant5.scss";
@import "./versions/2.0.6/defaultWidgets/pagesVariant6/scss/_pagesVariant6.scss";
@import "./versions/2.0.6/defaultWidgets/personAttributesVariant2/scss/_personAttributesVariant2.scss";
@import "./versions/2.0.6/defaultWidgets/personNameVariant1/scss/_personNameVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/personPersonalInformationVariant1/scss/_personPersonalInformationVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/personWorkgroupsVariant2/scss/_personWorkgroupsVariant2.scss";
@import "./versions/2.0.6/defaultWidgets/personsVariant1/scss/_personsVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/removeFunctionVariant1/scss/_removeFunctionVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/searchVariant1/scss/_searchVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/sidebarVariant1/scss/_sidebarVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/sliderVariant1/scss/_sliderVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/sliderVariant2/scss/_sliderVariant2.scss";
@import "./versions/2.0.6/defaultWidgets/textVariant1/scss/_textVariant1.scss";
@import "./versions/2.0.6/defaultWidgets/welcomeVariant1/scss/_welcomeVariant1.scss";
// endinject:defaultWidgets

// inject:specialWidgets:scss
@import "./versions/2.0.6/specialWidgets/logoBarVariant1/scss/_logoBarVariant1.scss";
@import "./versions/2.0.6/specialWidgets/sliderVariant3/scss/_sliderVariant3.scss";
// endinject:specialWidgets

// inject:kollaWidgets:scss
// endinject:kollaWidgets
